import React, { useEffect, useReducer, useState } from 'react';

import CoveoContentSearch from '@rotaryintl/app-coveo-content-search';

import { OneColumn } from '@components/Layouts/OneColumn';
import { TwoColumns } from '@components/Layouts/TwoColumns';
import { Submenu } from '@components/Submenu';
import Title from '@components/Title';

import SiteSearchForm from './SiteSearchForm/SiteSearchForm';
import SiteSearchList from './SiteSearchList';
import './styles.css';

import { SiteSearchFacets, SiteSearchFormValues } from '@domain/search';

import {
  getSiteParamsFromURL,
  validateSiteParamsFromURL,
} from '@use-cases/districts';

import { FEATURE_COVEO_SITE_SEARCH_PAGE, isEnabled } from '@utils/features';
import { getLangFromPath } from '@utils/localized-path';

import { useTranslation } from '@external/react-i18next';
import { useMenu } from '@hooks/menu';

import { SolrFacetFields } from '@typings/operations';

export type Lang = 'en' | 'it' | 'de' | 'es' | 'fr' | 'pt' | 'ja' | 'ko';

const SiteSearch: React.FC = location => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const coveoContentAccessToken = process.env
    .GATSBY_COVEO_CONTENT_ACCESS_TOKEN as string;
  const coveoContentOrganizationId = process.env
    .GATSBY_COVEO_CONTENT_ORGANIZATION_ID as string;

  const languageFromURL = getLangFromPath(window.location.href) || 'en';
  const {
    data: submenuData,
    error: submenuError,
    loading: submenuLoading,
  } = useMenu('menu-my-rotary-search-navigation', i18n.language);

  const [currentPage, setCurrentPage] = useState(1);

  const [facets, setFacets] = useState<SiteSearchFacets | SolrFacetFields>({
    contentType: [],
    documentMimetype: [],
    langcode: [],
    topic: [],
  });

  // The difference between the active filters and the above defined filters is
  // that the active filters are used for updating the actual results list. The
  // filters store the current state of the filter form, so every time the user
  // changes something in the form, the filters are updated (which can trigger
  // or not the update of the active filters).
  const defaultFilters = {
    keywords: '',
    contentType: '',
    documentMimeType: '',
    langcode: currentLanguage,
    topic: '',
  };

  const [filters, setFilters] = useReducer(
    (state: SiteSearchFormValues, action: SiteSearchFormValues) => ({
      ...state,
      ...action,
    }),
    defaultFilters
  );

  const [activeFilters, setActiveFilters] = useState<SiteSearchFormValues>({
    keywords: '',
    contentType: '',
    documentMimeType: '',
    langcode: '',
    topic: '',
  });
  if (filters.contentType !== 'document') {
    filters.documentMimeType = '';
  }

  useEffect(() => {
    const filtersFromURL = getSiteParamsFromURL();
    const validatedFilters = validateSiteParamsFromURL(filtersFromURL);
    const newFilter = { ...filters, ...validatedFilters };
    setFilters(newFilter);
    setActiveFilters(newFilter);
  }, []);

  if (isEnabled(FEATURE_COVEO_SITE_SEARCH_PAGE)) {
    return (
      <OneColumn>
        <Title className="siteSearchheading">
          {t('search.title', 'Search')}
        </Title>
        {!submenuLoading && !submenuError && submenuData.length && (
          <div className="siteSearchTitle">
            <Submenu
              items={submenuData}
              location={location}
              fullWidth={false}
            />
          </div>
        )}
        <div className="SublayoutSiteSearch mt-6 mb-20">
          <CoveoContentSearch
            key={languageFromURL}
            accessToken={coveoContentAccessToken}
            organizationId={coveoContentOrganizationId}
            searchHub="MyRotary-and-Rotary.org"
            queryPipeline="MyRotary-and-Rotary.org"
            language={languageFromURL as Lang}
            query=""
            showLanguageFacet={false}
          />
        </div>
      </OneColumn>
    );
  }

  return (
    <>
      <OneColumn>
        <Title>{t('search.title', 'Search')}</Title>
        {!submenuLoading && !submenuError && submenuData.length && (
          <Submenu items={submenuData} location={location} />
        )}
      </OneColumn>

      <TwoColumns
        className="search-page"
        bigColumnClassName="desktop-l:pl-16"
        small={
          <SiteSearchForm
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
            filters={filters}
            setFilters={setFilters}
            defaultFilters={defaultFilters}
            facets={facets as SolrFacetFields}
            setCurrentPage={setCurrentPage}
          />
        }
        big={
          <SiteSearchList
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setFacets={setFacets}
            setFilters={setFilters}
          />
        }
      />
    </>
  );
};

export default SiteSearch;
